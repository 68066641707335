import {axiosHandler, defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_REALTOR, FETCH_REALTORS} from '../constants/realtors';

import * as validator from '../validators/listing';


export function getRealtors(){
  const url = '/app/realtors/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        console.log(res)
        if(res.response != null){
          dispatch(fetchRealtors(res.response.realtors));
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}

export function getRealtor(body){
  const url = '/app/realtor/get';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, body);
      if(status == "ok"){
        console.log(res)
        if(res.response != null){
          dispatch(fetchRealtor(res.response.realtor));
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}



export function fetchRealtor(payload){
  return { type: FETCH_REALTOR, payload }
}

export function fetchRealtors(payload){
  return { type: FETCH_REALTORS, payload }
}