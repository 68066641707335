export function create(form){
    var status = "error"; var message = ""
    if(form.period == "" || form.period == null){
        message = "Please provide rental period"
    }else if(form.name == "" || form.name == null){
        message = "Please provide your name"
    }else if(form.email == "" || form.email == null){
        message = "Please provide your email"
    }else if(form.phone == "" || form.phone == null){
        message = "Please specify your phone"
    }else if(form.state == "" || form.state == null){
        message = "Please specify your state"
    }else if(form.location == "" || form.location == null){
        message = "Please specify your preferred location"
    }else if(form.bedroom == "" || form.bedroom == null){
        message = "Please specify the preferred number of bedroom"
    }else if(form.budget == "" || form.budget == null){
        message = "Please specify your budget"
    }else if(form.description == "" || form.description == null){
        message = "Please provide more description"
    }else{
        status = "success"
    }

    return [status, message]
}