import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography  from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';


import { postRequest } from '../../../actions/listings';
import Notify from '../../../components/alerts/Main';



export default function Main(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const [open, setOpen] = useState(props.open || false);
    const [form, setForm] = useState({state:"lagos"});
    const loading = useSelector(state => state.app.loading)
    
    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    function handleClose(){
        setOpen(false)
        props.close()
    }

    function handleChange(event){
        const {name, value} = event.target;
        setForm({...form, [name]:value})
    }



    function handleSubmit(){
        dispatch(postRequest(form, history))
    }

    return(
        <Dialog  fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
        <Typography variant="body1" style={{fontFamily:"UberBold"}}>  Can't find what I am looking for! </Typography>
        <Typography variant="body1" color="textSecondary">  Fill this form and we will contact you </Typography>
        </DialogTitle>
        
           

            
        <DialogContent>
          <DialogContentText>
           <Notify />
          </DialogContentText>

          <div style={{marginTop:20}} />

        <FormControl>
        <FormLabel id="marital-label">I am looking for</FormLabel>
        <RadioGroup
            row
            aria-labelledby="marital-label"
            name="period"
            onChange={handleChange}

        >
            <FormControlLabel value="shortlet" control={<Radio />} label="Shortlet" />
            <FormControlLabel value="normal" control={<Radio />} label="Full Rental" />
        </RadioGroup>
        </FormControl>   

<div style={{marginTop:10}} />
<TextField id="name"  name="name" onChange={handleChange} fullWidth 
label="Your Name" variant="filled" />

<div style={{marginTop:10}} />
<TextField fullWidth  id="email" name="email" onChange={handleChange} label="Your Email" variant="filled" />

<div style={{marginTop:10}} />
<TextField fullWidth  id="phone" name="phone" 
onChange={handleChange} label="Your Phone" variant="filled" />

<div style={{marginTop:10}} />


<div style={{marginTop:10}} />

<TextField fullWidth id="state" name="state" 
onChange={handleChange} defaultValue={"lagos"} label="State" select variant="filled">
<MenuItem value="lagos">Lagos</MenuItem>
<MenuItem value="abuja">Abuja</MenuItem>
</TextField>

<div style={{marginTop:10}} />

<TextField fullWidth  id="location" name="location" onChange={handleChange} label="Location" variant="filled" />



<div style={{marginTop:10}} />

<TextField fullWidth  id="bedroom" name="bedroom"
 onChange={handleChange} label="Bedrooms" variant="filled" />
<div style={{marginTop:10}} />


<TextField fullWidth  id="bedroom" name="budget"
    onChange={handleChange} label="Your Budget (Naira)" variant="filled" />
    <div style={{marginTop:10}} />



<TextField fullWidth id="description" name="description" 
onChange={handleChange}  multiline rows={5} label="Description" variant="filled" />

<div style={{marginTop:10}} />

<div style={{marginTop:20}} />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" fullWidth>
        <Typography variant="body1" style={{fontFamily:"UberBold"}}>Submit</Typography>
        </Button>
        </DialogActions>
      </Dialog>
    )
}