import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';

import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import {useParams} from 'react-router-dom';

import { getListingByUrl } from '../../actions/listings';
import { getRealtors} from '../../actions/realtors';

import Features from '../../components/mobile/listings/Features';
import Images from '../../components/mobile/listings/Images';
import Realtor from '../../components/mobile/listings/Realtor';



import SmallMapDisplay from '../../components/public/SmallMapDisplay';
import getSymbolFromCurrency from 'currency-symbol-map';

export default function Main(){
    const dispatch = useDispatch();
    const history = useHistory();
    const listing = useSelector(state => state.listing.listing);
    const realtors = useSelector(state => state.realtor.realtors);

    const [realtor, setRealtor] = useState(realtors.length > 0 ? 
        realtors.filter((item) => item.id == listing.realtor)[0] : {});
    let { url } = useParams();

    useEffect(() => {
        setRealtor(realtors.length > 0 ? realtors.filter((item) => item.id == listing.realtor)[0] : {});
    }, [realtors])

    useEffect(() => {
        const body = {url: url}
        dispatch(getListingByUrl(body, history));
        dispatch(getRealtors());
    }, []);

    return(
        <div style={{padding:10}}>

                <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item md={8}>
                <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <img style={{width:150}}  src={require("../../images/logo/1x/Asset 1.png")}/>
                </div>
                </Grid>
                </Grid>

                
                <div style={{marginTop:20}}/>
                <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item md={6}>
                <Button onClick={() => history.push("/")} variant="outlined">
                <Typography variant="body1" style={{fontFamily:"UberBold"}}>
                All Listings
                </Typography>
                </Button>

                <div style={{marginTop:20}} />
                
              
                
                <div style={{marginTop:10}}>
                <Typography variant="h6" style={{fontFamily:"UberBold"}}>{listing.title}</Typography>
                <Typography variant="body1" color="textSecondary">{listing.address}</Typography>
                </div>

                <div style={{marginTop:10}}>

                <Typography variant="body1" color="textSecondary" >RENT</Typography>
                    <Typography variant="h6" style={{fontFamily:"UberBold"}}>
                       {listing.price}
                    </Typography>
                </div>
                
                
                

                <div style={{marginTop:10}}>
                <Typography variant="body2" align="justify">
                {listing.description}
                </Typography>
                </div>

                <div style={{marginTop:20}} />
                <Divider inset  />

                <div style={{marginTop:20}}>
                <Features listing={listing} />
                </div>

                <Divider inset  />

                

                <div style={{marginTop:20}}>
                <Images listing={listing} />
                </div>

               

                    
               

                
                   


                </Grid>
                <Grid item xs={12}>
                    {realtor && <Realtor realtor={realtor} />}
                
                
                        
                
                </Grid>
                </Grid>
           
        </div>
    )
}