import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { postRequest } from '../../actions/listings';

export default function Main(){
    const dispatch = useDispatch();
    const history = useHistory()

    const [form, setForm] = useState({state:"lagos"});

    function handleChange(event){
        const {name, value} = event.target;
        setForm(form => ({...form,  [name]:value}));
    }

    function handleSubmit(){
        dispatch(postRequest(form, history))
    }


    return(
        <div>
        <Box sx={{padding:2, borderRadius:5, height:"700px", width: '100%', backgroundColor:"#E5E4E2" }}>
            <Typography variant="h6" style={{fontFamily:"UberBold"}}>  Can't find what I am looking for! </Typography>
            <Typography variant="body1" color="textSecondary">  Fill this form and we will contact you </Typography>

            <div style={{marginTop:20}} />

            <FormControl>
            <FormLabel id="marital-label">I am looking for</FormLabel>
            <RadioGroup
                row
                aria-labelledby="marital-label"
                name="period"
                onChange={handleChange}

            >
                <FormControlLabel value="shortlet" control={<Radio />} label="Shortlet" />
                <FormControlLabel value="normal" control={<Radio />} label="Full Rental" />
            </RadioGroup>
            </FormControl>   

            <div style={{marginTop:10}} />
            <TextField id="name"  name="name" onChange={handleChange} fullWidth 
            label="Your Name" variant="outlined" />

            <div style={{marginTop:10}} />
            <TextField fullWidth  id="email" name="email" onChange={handleChange} label="Your Email" variant="outlined" />
            
            <div style={{marginTop:10}} />
            <TextField fullWidth  id="phone" name="phone" 
            onChange={handleChange} label="Your Phone" variant="outlined" />

            <div style={{marginTop:10}} />

           
            <div style={{marginTop:10}} />

            <Grid container direction="row" spacing={1}>
                <Grid item md={4}>
                <TextField fullWidth id="state" name="state" 
            onChange={handleChange} defaultValue={"lagos"} label="State" select variant="outlined">
            <MenuItem value="lagos">Lagos</MenuItem>
            <MenuItem value="abuja">Abuja</MenuItem>
            </TextField>
                </Grid>
                <Grid item md={8}>
                <TextField fullWidth  id="location" name="location" onChange={handleChange} label="Location" variant="outlined" />
           
                </Grid>
               
            </Grid>
            <div style={{marginTop:10}} />
            
            
            <Grid container direction="row" spacing={1}>
                <Grid item md={4}>
                <TextField fullWidth  id="bedroom" name="bedroom"
             onChange={handleChange} label="Bedrooms" variant="outlined" />
            <div style={{marginTop:10}} />
                </Grid>
                <Grid item md={8}>
                <TextField fullWidth  id="bedroom" name="budget"
                onChange={handleChange} label="Your Budget (Naira)" variant="outlined" />
                <div style={{marginTop:10}} />
                </Grid>
            </Grid>
            

            <TextField fullWidth id="description" name="description" 
            onChange={handleChange}  multiline rows={5} label="Description" variant="outlined" />
            
            <div style={{marginTop:10}} />

            <div style={{marginTop:20}} />
            <Button onClick={handleSubmit} variant="contained" fullWidth>
                <Typography variant="body1" style={{fontFamily:"UberBold"}}>Submit</Typography>
            </Button>
        </Box>
        </div>
    )
}