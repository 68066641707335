import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';


export default function Main(props){
    const realtor = props.realtor;
    const listing = useSelector(state => state.listing.listing);
    return(
        <div>
             <Box sx={{padding:2, borderRadius:5, height:"350px", width: '100%', backgroundColor:"#E5E4E2" }}>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
        
        <Avatar style={{width:60, height:60}} />
        <div style={{marginTop:10}}/>
        <Typography variant="h6" style={{fontFamily:"UberBold"}}>{realtor.name}</Typography>
        <Typography variant="body1" color="textSecondary">{realtor.company_name}</Typography>
        </div>
         
       

        <div style={{marginTop:20}}>
        <Typography variant="body1" color="textSecondary" align="justify">
        {realtor.about}
        </Typography>
        
        </div>

        <div style={{marginTop:20}}/>
        <Grid container direction="row" spacing={1} >
        <Grid item>
            <MailIcon />
        </Grid>
        <Grid item>
        <Typography  color="textSecondary">{realtor.email}</Typography>
        </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} >
        <Grid item>
            <CallIcon />
        </Grid>
        <Grid item>
        <Typography  color="textSecondary">{realtor.phone}</Typography>
        </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} >
        <Grid item>
            <TwitterIcon />
        </Grid>
        <Grid item>
        <Typography  color="textSecondary">{realtor.twitter}</Typography>
        </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
        <Grid item>
            <InstagramIcon />
        </Grid>
        <Grid item>
        <Typography  color="textSecondary">{realtor.instagram}</Typography>
        </Grid>
        </Grid>
        </Box>
        </div>
    )
}