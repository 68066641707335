import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';

import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';




import {useParams} from 'react-router-dom';

import { getListingByUrl } from '../../actions/listings';
import { getRealtors} from '../../actions/realtors';




import SmallMapDisplay from '../../components/public/SmallMapDisplay';
import getSymbolFromCurrency from 'currency-symbol-map';

export default function Main(){
    const history = useHistory()

    return(
        <div style={{padding:10}}>

                <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item md={8}>
                <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <img style={{width:150}}  src={require("../../images/logo/1x/Asset 1.png")}/>
                </div>
                </Grid>
                </Grid>

                
                <div style={{marginTop:20}}/>
                <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item md={6}>
                

                <div style={{marginTop:20}} />
                

                <div style={{display:"flex", justifyContent:"center"}}>
                    <img style={{width:550}}  src={require("../../assets/svg/done.svg")}/>
                </div>

                <div style={{marginTop:20}} />
                
                <div style={{display:"flex", justifyContent:"center"}}>
                    <Typography variant="h6" style={{fontFamily:"UberBold"}}>
                       Awesome!, your request has been posted. We will get back to you soon
                    </Typography>
                </div>
               
                <div style={{marginTop:20}} />
                <div style={{display:"flex", justifyContent:"center"}}>
                <Button variant="outlined" onClick={() => history.push("/")}>
                <Typography variant="body1" style={{fontFamily:"UberBold"}}>
                All Listings
                </Typography>
                </Button>
                </div>

                    
               

                
                   


                </Grid>
               
                </Grid>
           
        </div>
    )
}