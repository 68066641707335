import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {BrowserRouter as Router, Switch, Route, Link, BrowserRouter} from "react-router-dom";


import Home from './mobile/main/Main';
import Listing from './mobile/main/Listing';
import NotFound from './mobile/main/NotFound';






const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

export default function Main(){
    return(
        <Mobile>
        <Switch>
             <Route exact path="/" component={Home} />
             <Route exact path="/r/notfound" component={NotFound} />
             <Route exact path="/:url" component={Listing} />
        </Switch>
        </Mobile>
    )
}
