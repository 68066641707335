import React, { useEffect, useState } from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import AddIcon from '@mui/icons-material/Add';

import 'react-alice-carousel/lib/alice-carousel.css';


import {page} from '../../styles/main';
import { Typography, requirePropFactory } from '@mui/material';
import {getListings} from '../../actions/listings';
import GridItem from '../../components/mobile/listings/GridItem';
import RequestForm from '../../components/mobile/requests/NewRequest';
import NewRequestModal from '../../components/mobile/requests/NewRequestModal';


const classes = {
    root:{
        marginTop:10
    }
}

const styles = {
    media: {
      height: '100%',
      paddingTop: '75%', // 16:9,
      marginTop:'30'
    },
};






export default function Main(){
    const history = useHistory();
    const dispatch = useDispatch();
    const listings = useSelector(state => state.listing.listings);
    const [data, setData] = useState(listings ? listings : []);
    const [modal, setModal] = useState(false);



    useEffect(() => {
        dispatch(getListings())
    }, [])

    useEffect(() => {
      setData(listings ? listings : []);
    }, [listings])


    function handleNewRequest(){
      setModal(true)
    }


    return(
      <div>
        <NewRequestModal open={modal} close={() => setModal(false)} />
      <Grid style={{height:"100vh"}} justifyContent="center"  container direction="row">
        <Grid item xs={12} lg={10} sm={12} md={10} style={{marginTop:0, padding:10,}}>
        <div style={{marginTop:10}}/>
        <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <img style={{width:150}}  src={require("../../images/logo/1x/Asset 1.png")}/>
        </div>
        <div style={{marginTop:30}}/>

        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item md={8} xs={12}>
            <Grid container direction="row">
              <Grid item md={6} xs={12}>
              
              <Grid container direction="row" spacing={1} alignItems="flex-end">
                <Grid item>
                <Typography variant="h4" style={{fontFamily:"UberBold"}}>Listings</Typography> {" "}
                </Grid>
                <Grid item>
                <Chip label="Add Listing" component="a" href="#basic-chip" clickable />
                </Grid>
                <Grid item>
                <Chip label="Make Request" onClick={handleNewRequest} component="a" href="#basic-chip" clickable />
                </Grid>
              </Grid>
              
             
            
              
              </Grid>
              </Grid>
              <div style={{marginTop:20}} />
              <Grid container direction="row">
              <Grid item xs={12}>
              <Statistics />
              </Grid>
            </Grid>
          
        
          </Grid>
          <Grid item md={4}>
          
          </Grid>
        </Grid>
        

        <div style={{marginTop:10}} />
        <Grid container direction="row" spacing={1}>
          <Grid item md={8} xs={12} sm={12}>
           
           
            <DataGrid data={data} />
          </Grid>
          <Grid item md={4}>
            
          </Grid>
        </Grid>   
             
      </Grid>
      </Grid>     
      </div>
    )
}

function DataGrid(){
  const dispatch = useDispatch();
  const listings = useSelector(state => state.listing.listings);
  const [max, setMax] = useState(6);
  const [data, setData] = useState(listings ? listings : []);

  useEffect(() => {
    setData(listings ? listings : [])
  }, [listings])


  useEffect(() => {
    dispatch(getListings())
  }, [])

  function handleFilter(searchText, location){
     
    if(location == "all"){
      setData(listings.filter((item) => item.state != location).filter(item => item.title.includes(searchText))) 
    }else{
      setData(listings.filter((item) => item.state == location).filter(item => item.title.includes(searchText)))
    }  
 }

 function handleClick(){
   if(max < data.length){
     setMax(max + 6)
   }
   
 }


  return(
      <>
       <Search update={(search, location) => handleFilter(search, location)} />
       <div style={{marginTop:20}} />
        <Grid container direction="row" spacing={1} >
        {data.map((item, index) => (
            index < max &&
            <Grid item md={4} xs={12} sm={6}>
              <GridItem data={item} />
            </Grid>
        ))}
      </Grid>
      
      {max < data.length && 
        <div style={{marginTop:10, display:"flex", flexDirection:"row", justifyContent:"center"}} >
        <Button onClick={handleClick} variant="outlined"><Typography variant="body1"><b>Load More</b></Typography></Button>
        </div>
      }
      
      </>
      
  )
}

function Statistics(){
  const listings = useSelector(state => state.listing.listings);

  return(
    <Grid container direction="row" justifyContent="space-between" spacing={1}>
      <Grid item md={4}>
        <Typography  variant="body1" color="textSecondary">Listings</Typography>
        <Typography  variant="h4"  style={{fontFamily:"UberBold"}}>{listings.length}</Typography>
      </Grid>
      <Grid item md={4}>
        <Typography  variant="body1" color="textSecondary">New</Typography>
        <Typography  variant="h4"  style={{fontFamily:"UberBold"}}>0</Typography>
      </Grid>
      <Grid item md={4}>
        <Typography  variant="body1" color="textSecondary">Rented</Typography>
        <Typography  variant="h4" style={{fontFamily:"UberBold"}}>0</Typography>
      </Grid>
    </Grid>
  )
}


function Search(props){

  const [form, setForm] = useState({search: "", location:"all"});
  
  function handleChange(event){
    const {name, value} = event.target;
    if(name == "search"){
      props.update(value, form.location)
    }else{
      props.update(form.search, value)
    }
    setForm(form => ({...form, [name]: value}))
  }

  return(
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item md={6} xs={8}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          onChange={handleChange}
          label="Search"
          variant="filled"
        />
        </Grid>
        <Grid item md={4} xs={4}>
        <TextField
          required
          fullWidth
          id="location"
          name="location"
          onChange={handleChange}
          label="Location"
          select
          defaultValue="all"
          variant="filled"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"lagos"}> Lagos </MenuItem>
          <MenuItem value={"abuja"}> Abuja </MenuItem>
        </TextField>
        </Grid>
        <Grid item md={2}>
         
        </Grid>
      </Grid>
    </div>
  )
}



