import {axiosHandler, defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_LISTING, FETCH_LISTINGS, FETCH_REQUEST} from '../constants/listings';

import * as validator from '../validators/request';

export function getListings(){
  const url = '/listing/listings/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      console.log(res)
      if(status == "ok"){
        if(res.response != null){
          dispatch(fetchListings(res.response.listings));
        }else{

        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}

export function postRequest(body, history){
  const url = '/listing/requests/create';
  return async dispatch => {
    var [status, message] = validator.create(body);
      if(status == "success"){
        dispatch(wait(true))
        const [res, status] = await defaultHandler(url, body);
        console.log(res)
        if(status == "ok"){
          if(res.response != null){
            dispatch(fetchRequest(res.response.request));
            history.push("/r/success")
          }
        }else{
          dispatch(unexpectedError())
        }
        dispatch(wait(false))
    }else{
      dispatch(notify({message:message, status:"error", show: true}));  
    }
      
    };
}

export function getListingByUrl(body, history){
  const url = '/listing/listings/url';
  
    return async dispatch => {
      dispatch(wait(true))
        const [res, status] = await defaultHandler(url, body);
        console.log(res);
        if(status == "ok"){
          if(res.response != null){
            if(res.response.listing.length > 0){
              dispatch(fetchListing(res.response.listing[0]));
            }else{
               history.push("/r/notfound")
            }
          }else{
  
          }
        }else{
          dispatch(unexpectedError())
        }
        dispatch(wait(false))
      };
}

export function fetchListing(payload){
  return { type: FETCH_LISTING, payload }
}

export function fetchRequest(payload){
  return { type: FETCH_REQUEST, payload }
}

export function fetchListings(payload){
  return { type: FETCH_LISTINGS, payload }
}